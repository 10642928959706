import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { getSimulationStepsByType } from 'helpers/getSimulationSteps'
import { setInfoStepThreePageTwo } from 'pages/mainStep/mainSlice'

export type DossierState = {
  dossierChoices: any
  type: string
  stepActive: number
  intermediateStep: number;
  direction: string;
  showHeader: boolean;
  modalContent: string;
  prets: any[];
}

const initialState: DossierState = {
  dossierChoices: null,
  type: "",
  stepActive: 0,
  intermediateStep: 0,
  direction: "",
  showHeader: true,
  modalContent: "",
  prets: []
}


export const dossierSimuSlice = createSlice({
  name: 'dossierSimu',
  initialState,
  reducers: {
    setPrets: (state, action) => {
      state.prets = action.payload
    },
    setDossierSimu: (state, action) => {
      state.dossierChoices = {
        ...state.dossierChoices,
        ...action.payload.dossierChoices,
      };
      state.stepActive = action.payload.step
      state.intermediateStep = action.payload.intermediateStep
      state.type = action.payload.type
    },
    setIntermediateStep: (state, action) => {
        state.intermediateStep = action.payload;
      },
    setActiveStep: (state, action) => {
    state.stepActive = action.payload.stepActive;
    },
    setType: (state, action) => {
        state.type = action.payload;
    },
    setShowHeader: (state, action) => {
      state.showHeader = action.payload
    },
    setModalContent: (state, action) => {
      state.modalContent = action.payload;
    },
    resetDossierSimu: (state) => {
      state.dossierChoices = null;
      state.type = "";
      state.stepActive = 0;
      state.intermediateStep = 0;
    },
    resetDossierStore(state) {
      state = initialState;
    },
    handleBack: (state) => {
      console.log("intermerdiate", state.intermediateStep);
        state.direction = "moveToLeftFromRight";
        if (state.stepActive != 0) {
            if (state.stepActive === 1)
            {
                const list = getSimulationStepsByType(state.type);
                state.intermediateStep = list[0].intermediateStep - 1;
            }
           
            else
                state.intermediateStep = 0
            state.stepActive = state.stepActive - 1;
        }
        else {
          if (state.type === "cbno" && state.intermediateStep === 10)
            state.intermediateStep = 8;
          else if (state.type === "mri" && state.intermediateStep === 10 && state.dossierChoices.commercialParking === null) {
              state.intermediateStep = 8;
            }
          else if (state.type === "mri" && state.intermediateStep === 6) {
            state.intermediateStep = 4;
          }
          else if (state.type === "cbno" && (state.intermediateStep === 2 || state.intermediateStep === 9)) {
            state.intermediateStep = 0;
          } else if (state.type === "mri" && state.intermediateStep === 15) {
            state.intermediateStep = 0;
          } else {
            state.intermediateStep = state.intermediateStep - 1;
          }
        }
    },
    handleNext: (state) => {
        state.direction = "moveToRightFromLeft"

        if (state.stepActive != 0) {
            state.stepActive = state.stepActive + 1;
            state.intermediateStep = 0;
        }
        else {
            const list = getSimulationStepsByType(state.type);
            if (state.intermediateStep === (list[0].intermediateStep - 1)) {
                state.intermediateStep = 0;
                state.stepActive = state.stepActive + 1;
            }
            else {
                state.intermediateStep = state.intermediateStep + 1;
            }
        }
    },
    setDossierChoices: (state, action) => {
        state.dossierChoices = {
          ...state.dossierChoices,
          ...action.payload.dossierChoices,
      };
    }
  }
})

export const dossierActions = dossierSimuSlice.actions

export const { resetDossierStore, setDossierSimu, resetDossierSimu, setActiveStep, setIntermediateStep, setPrets, handleBack, handleNext, setType, setDossierChoices, setShowHeader, setModalContent } = dossierActions
export const selectDossierChoices = (state: RootState) => state.dossierSimuSlice.dossierChoices
export const selectDossierType = (state: RootState) => state.dossierSimuSlice.type
export const selectDossierStepActive = (state: RootState) => state.dossierSimuSlice.stepActive
export const selectDossierIntermediateStep = (state: RootState) => state.dossierSimuSlice.intermediateStep
export const selectDirection = (state: RootState) => state.dossierSimuSlice.direction
export const selectShowHeader = (state: RootState) => state.dossierSimuSlice.showHeader
export const selectModalType = (state: RootState) => state.dossierSimuSlice.modalContent
export const selectDossierPrets = (state: RootState) => state.dossierSimuSlice.prets
export const selectPrets = (state: RootState) => state.dossierSimuSlice.prets

export default dossierSimuSlice.reducer