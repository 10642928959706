import { PageTransition } from "@steveeeie/react-page-transition"
import { Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import { ActivitePrincipale, getActivitePrincipale, getListActivitesPrincipales, getListActivitesPrincipalesParents } from "api/referentielsAPI";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { handleNext, selectDirection, selectDossierChoices, selectDossierIntermediateStep, setIntermediateStep } from "features/dossierSimuSlice";
import React, { useEffect, useState } from "react"

type Props = {
    handleChoice: (fieldName: string, value: boolean | string | number) => void;
}

const fetchActivites = async () => {
    const parents = await getListActivitesPrincipalesParents();
    const activites = await getListActivitesPrincipales();
  
    const listWithChildren = await Promise.all(
      parents.items.map(async (parent: ActivitePrincipale) => {
        const children = activites?.items?.filter((child: ActivitePrincipale) => child.parentId === parent.id)
        return { ...parent, children };
      })
    );
    return listWithChildren;
  };

const ActiviteProCBNO: React.FC<Props> = ({handleChoice}) => {
    const intermediateStep = useAppSelector(selectDossierIntermediateStep);
    const direction = useAppSelector(selectDirection);
    const dossierCBNO = useAppSelector(selectDossierChoices);
    const dispatch = useAppDispatch();

    const [options, setOptions] = useState<any[]>([]);

    const [checkedItems, setCheckedItems] = useState<any>(
        dossierCBNO?.ActivitePrincipaleId ? dossierCBNO?.ActivitePrincipaleId
        : null
    );
  
    const loadActivites = async () => {
      const activitesWithChildren = await fetchActivites();
  
      const formattedOptions = activitesWithChildren.flatMap((parent: any) => [
        { label: parent.designation, value: parent.id, parentId: null },
        ...parent.children.map((child: any) => ({
          label: `${child.code} - ${child.designation}`,
          value: child.id,
          parentId: parent.id,
        })),
      ]);
  
      setOptions(formattedOptions);
    };
  
    useEffect(() => {
      loadActivites();
    }, []);

    const handleSelection = async (value: any) => {
        setCheckedItems(value);
        const activity = await getActivitePrincipale(value);
    
        if (activity.acceptabiliteMRI) {
          handleChoice("ActivitePrincipaleId", activity?.id);
          dispatch(handleNext());
        }
        else {
          dispatch(setIntermediateStep(9))
        }
    }

    return (
        <PageTransition
          preset={direction}
          transitionKey={intermediateStep?.toString()}
          enterAnimation={""}
          exitAnimation={""}
        >
            <div className="question-container" id="activite-pro">
                <p className="mg-r5 mg-l5">
                    De quelle activité, l'usage profesionnel relève t-il ?
                </p>
                <div className="form-item w-85 ">
                    <FormItem name={"activitepro"}>
                    <Select dropdownStyle={{backgroundColor: "white"}} className="w-80" onChange={(e: any) => handleSelection(e)} value={checkedItems}>
                    {
                        options?.map((currentOption: any) => {
                            return (
                            <Select.Option value={currentOption?.value}>
                                <span>{currentOption?.label}</span>
                            </Select.Option>
                            )
                        })
                    }
                    </Select>
                    </FormItem>
                </div>
            </div>
         </PageTransition>
    )
}

export default ActiviteProCBNO;