import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { handleNext, selectDossierChoices, setIntermediateStep } from "features/dossierSimuSlice";
import { Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import { ActivitePrincipale, getActivitePrincipale, getListActivitesPrincipales, getListActivitesPrincipalesParents } from "api/referentielsAPI";

type Props = {
  handleOnClick: (
    fieldName: string,
    value: boolean | string | any
  ) => void;
  isSide?: boolean
};

const fetchActivites = async () => {
  const parents = await getListActivitesPrincipalesParents();
  const activites = await getListActivitesPrincipales();

  const listWithChildren = await Promise.all(
    parents.items.map(async (parent: ActivitePrincipale) => {
      const children = activites?.items?.filter((child: ActivitePrincipale) => child.parentId === parent.id)
      return { ...parent, children };
    })
  );
  return listWithChildren;
};

const ActivitePro: React.FC<Props> = ({ handleOnClick, isSide }) => {
  const mri = useAppSelector(selectDossierChoices);
  const dispatch = useAppDispatch();


  const [options, setOptions] = useState<any[]>([]);

  const [checkedItems, setCheckedItems] = useState<any>(
    mri && mri?.ActivitePrincipaleId
      ? mri.ActivitePrincipaleId
      : null
  );

  const loadActivites = async () => {
    const activitesWithChildren = await fetchActivites();

    const formattedOptions = activitesWithChildren.flatMap((parent: any) => [
      { label: parent.designation, value: parent.id, parentId: null },
      ...parent.children.map((child: any) => ({
        label: `${child.code} - ${child.designation}`,
        value: child.id,
        parentId: parent.id,
      })),
    ]);

    setOptions(formattedOptions);
  };

  useEffect(() => {
    loadActivites();
  }, []);

  useEffect(() => {
    if (mri && mri?.firstElements) {
      const isCommerces = mri?.firstElements.find((el: any) => el.label === "Commerces").value
      if (!isCommerces) {
        dispatch(handleNext())
      }
    }
  }, [mri])

  const handleNextStepCheck = async (choiceActivity : any) => {
    const activity = await getActivitePrincipale(choiceActivity);

    if (activity.acceptabiliteMRI) {
      handleOnClick("ActivitePrincipaleId", activity?.id);
      dispatch(handleNext());
    }
    else {
      dispatch(setIntermediateStep(15))
    }
  }

  return (
    <div className={`${!isSide ? "max-w1300 mg-0-auto ccc-body-step-2" : ""}`} id="activite-pro">
      <div className="w-100 text-center border-box radius-15 cccbs2r1">
        {
          <div className="mg-b50">
              <>
                <div>
                  <p className="font-18 lh-22 text-dark font-w700 cccbs2r1r1">
                      De quelle activité, l'usage profesionnel relève t-il ?
                  </p>
                </div>
              </>
          </div>
        }
        <div
          className={`${!isSide ? "d-flex flex-hor-center cccbs2r1r2" : "d-flex flex-hor-start"} w-100`}
        >
            <div className="form-item w-80 ">
                <FormItem name={"activitepro"}>

                <Select dropdownStyle={{backgroundColor: "white"}} className="w-80" onChange={(e: any) => setCheckedItems(e)} value={checkedItems}>
                  {
                    options?.map((currentOption: any) => {
                        return (
                          <Select.Option value={currentOption?.value}>
                              <span>{currentOption?.label}</span>
                          </Select.Option>
                        )
                    })
                  }
                </Select>
                </FormItem>
          </div>
        </div>
      </div>
      {
            <div className="d-flex flex-hor-end mg-t10">
              <button
                className="submitButton w-30"
                onClick={() => handleNextStepCheck(checkedItems)}
                disabled={checkedItems === null}
              >
                Étape suivante
              </button>
            </div>
        }
    </div>
  );
};

export default ActivitePro;
