import { useAppDispatch, useAppSelector } from "app/hooks";
import { handleNext, selectDossierIntermediateStep, setDossierChoices, setType } from "features/dossierSimuSlice";
import Georisks from "./forms/georisks";
import CoOwnerShipOne from "./forms/stepTwo";
import CoOwnerShipTwo from "./forms/stepThree";
import CoOwnerShipThree from "./forms/stepFour";
import CoOwnerShipFive from "./forms/stepFive";
import CoOwnerShipSix from "./forms/stepSix";
import Eligibility from "./forms/stepSeven";
import CoOwnerShipEight from "./forms/stepEight";
import CoOwnerShipNine from "./forms/stepNine";
import CoOwnerShipTen from "./forms/stepTen";
import CoOwnerShipEleven from "./forms/stepEleven";
import StepTwelve from "./forms/stepTwelve";
import CoOwnerShipThirteen from "./forms/stepThirteen";
import StepFourteen from "./forms/stepFourteen";
import ActivitePro from "./forms/activitePro";
import IsNotEligible from "./forms/isNotEligible";


const StepOne: React.FC = () => {

    const intermediateStep = useAppSelector(selectDossierIntermediateStep);
    const dispatch = useAppDispatch();

    const handleOnClick = (
        fieldName: string,
        value: boolean | string | any[]
      ) => {
        const payload = {
          dossierChoices: {
            [fieldName]: value,
          },
        };
        dispatch(setType("mri"));
        dispatch(setDossierChoices(payload));
      };

    const getContent = () => {
        switch (intermediateStep) {
            case 0:
              return <Georisks  handleOnClick={handleOnClick} />;
            case 1:
              return <CoOwnerShipOne  handleOnClick={handleOnClick} />;
            case 2:
              return (
                <CoOwnerShipTwo handleOnClick={handleOnClick} />
              );
            case 3:
              return <CoOwnerShipThree handleOnClick={handleOnClick} />;
            case 4:
              return <CoOwnerShipFive handleOnClick={handleOnClick} />;
            case 5:
              return <ActivitePro handleOnClick={handleOnClick} />;
            case 6:
              return <CoOwnerShipSix  handleOnClick={handleOnClick} />;
            case 7:
              return (
                <Eligibility handleOnClick={handleOnClick} />
              );
            case 8:
              return (
                <CoOwnerShipEight handleOnClick={handleOnClick} />
              );
            case 9:
              return <CoOwnerShipNine handleOnClick={handleOnClick} />;
            case 10:
              return <CoOwnerShipTen handleOnClick={handleOnClick} />;
            case 11:
              return (
                <CoOwnerShipEleven handleOnClick={handleOnClick} />
              );
            case 12:
              return (
                <StepTwelve  handleOnClick={handleOnClick}/>
              );
            case 13:
              return (
                <CoOwnerShipThirteen handleOnClick={handleOnClick} />
              );
            case 14:
              return (
                <StepFourteen
                  handleOnClick={handleOnClick}
                />
              );
            case 15: 
                return (
                  <IsNotEligible />
                )
          }
    }


    return (
        <>
        {getContent()}
        </>
    )
}

export default StepOne